import * as THREE from 'three';
import particleImage from './particle.png';

import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';

document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("header-container");
    const canvas = (document.getElementById("header-background") as HTMLCanvasElement);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        75, 
        container.clientWidth / container.clientHeight,
        0.1,
        1000
    );
    var renderer = new THREE.WebGLRenderer({
        canvas, 
        antialias: true,
        alpha: true
    });
    renderer.setSize(container.clientWidth, container.clientHeight);
    

    var particleCount = 8000,
    particles = new THREE.Geometry(),
    pMaterial = new THREE.PointsMaterial({
      color: 0x0098C8,
      size: 0.1,
      map: THREE.ImageUtils.loadTexture(
        particleImage
      ),
      blending: THREE.AdditiveBlending,
      transparent: true,
      depthTest: false,
      sizeAttenuation: true,
      opacity: 0.1,
    });

    let pointGeometry = new THREE.Geometry()
    pointGeometry.vertices = new Array(particleCount).fill(0).map(_ => {
        return new THREE.Vector3(
            Math.random() * 20 - 10,
            Math.random() * 20 - 10,
            Math.random() * 20 - 10
        );
    });

    let pointTargets = new Array(particleCount).fill(0).map((_, index) => {
        return index;
    });

    let targetShape = pointGeometry.vertices.map((vector) => vector.clone())


    const setTargetScene = (scene: THREE.Group) => {
        scene.traverse((obj) => {
            const mesh = obj as THREE.Mesh;
            
            if (mesh.isMesh) {
                const geometry = mesh.geometry as THREE.BufferGeometry;

                const positionAttribute = geometry.attributes.position;
                const boundingBox = new THREE.Box3().setFromObject(mesh);
                const center = new THREE.Vector3();
                const size = new THREE.Vector3();
                boundingBox.getCenter(center);
                boundingBox.getSize(size);
                mesh.geometry.translate(-center.x, -center.y, -center.z);
                const largestSize = Math.max(size.x, size.y, size.z);
                const scale = 1.0/largestSize * 6;
                mesh.geometry.scale(scale, scale, scale);
                
                targetShape.forEach((vertex, index) => {
                    const randomVertexIndex = Math.random() * positionAttribute.count | 0;
                    vertex.set(
                        positionAttribute.getX(randomVertexIndex),
                        positionAttribute.getY(randomVertexIndex),
                        positionAttribute.getZ(randomVertexIndex)
                    );
                });
                pointGeometry.verticesNeedUpdate = true;
            }
        })
    }

    var loader = new OBJLoader();
    const scenesToLoad = [
        "laptop",
        "androidresampled",
        "cloud"
    ];

    let currentSceneIndex = 0;
    const loadNextScene = () => {
        const currentScene = `/models/${scenesToLoad[currentSceneIndex]}.obj`;
        currentSceneIndex = (currentSceneIndex + 1) % scenesToLoad.length;
        loader.load(currentScene, (scene) => {
            setTargetScene(scene);
            setTimeout(loadNextScene, 10000);
        });
    }
    loadNextScene();
    
    var particleSystem = new THREE.Points(
        pointGeometry,
        pMaterial
    );
    const particleContainer = new THREE.Group();
    particleContainer.add(particleSystem);
    particleContainer.position.set(2,3,0);
    scene.add(particleContainer);
    camera.position.z = 10;

    window.addEventListener('resize', () => {
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;
        renderer.setSize(container.clientWidth, container.clientHeight);
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();
    }, false);

    const animate = () => {
        pointGeometry.vertices.forEach((vertex, index) => {
            const target = targetShape[pointTargets[index] % targetShape.length];
            const randomOffset = new THREE.Vector3(Math.random(), Math.random(), Math.random());
            const distance = new THREE.Vector3().copy(target).sub(vertex)
            const distanceNormalized = distance.clone().normalize();
            vertex.addScaledVector(distanceNormalized, 0.03);

            if (distance.lengthSq() < 0.0001 && Math.random() > 0.999) {
                pointTargets[index] = (pointTargets[index] + 1) % pointTargets.length;
            }
        });
        pointGeometry.verticesNeedUpdate = true;
        // particleSystem.rotation.x += 0.01;
        particleSystem.rotation.y += 0.001;

        requestAnimationFrame(animate);
        renderer.render(scene, camera);
    }
    particleSystem.rotation.y = -1.5;
    animate();
});